import DataTable from 'components/DataTable'
import { LinkContainer } from 'components/DataTable/style'
import Modal from 'components/Modal'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import ViewInfoLabel from 'components/ViewInfoLabel'
import { useUpdateDataTable } from 'hooks/dataTable'
import moment from 'moment'
import {
  FormPlanFrequency,
  IPlanFrequencyDefaultValues
} from 'pages/Commercial/Plans/components/FormPlanFrequency'
import { apiDeletePlanFrequency } from 'pages/Commercial/Plans/domain/api'
import { MenuTable, MenuTableRow } from 'pages/Menus/View/styles'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { currencyMask } from 'utlis/mask'
import { v4 as uuidv4 } from 'uuid'
import { Alert } from '../../../../../components/Alert'
import Container from '../../../../../components/Container'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import { FormAddItem } from '../components/FormAddItem'
import { IComposition, useMenuFoodContext } from '../contexts'
import { apiDelete, apiDeleteMenuFoodDishes, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { headerMenuDishes, headerMenuInstitutions } from '../domain/headers'
import { nameActions } from '../domain/info'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { FaDownload } from 'react-icons/fa'
import { FormPdfItems } from '../components/FormPdfItems'

const MenuFoodView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [menuFood, setMenuFood] = useState<IMenuFoodData | null>(null)
  const [modalTypeOpenItems, setModalTypeOpenItems] =
    useState<TModalType>('closed')
  const [modalPdfItems, setModalPdfItems] = useState<{
    open: boolean
  }>({
    open: false
  })
  const [actualMenuFoodDish, setActualMenuFoodDish] = useState<number>()
  const { updateDataTable } = useUpdateDataTable()
  const { setComposition, setHasItemsSelect, setNewComposition } =
    useMenuFoodContext()
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const [alertRemovePlanFrequency, setAlertRemovePlanFrequency] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const [alertRemoveMenuFoodDish, setAlertRemoveMenuFoodDish] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const [paramsDishes, setParamsDishes] = useState([])

  const searchParams = useRef([{ menu_food_id: `${id}` }])
  const searchParametersAuditLog = useRef([
    { entity: 'MenuFood', entity_id: id }
  ])
  const refModalFrequency = useRef(null)
  const refModalItems = useRef(null)

  const handleClickOnCloseModalPdfItems = () => {
    setModalPdfItems({
      open: false
    })
  }

  const handleClickOnCloseItems = () => {
    setModalTypeOpenItems('closed')
    setNewComposition([
      {
        id: uuidv4(),
        dish_id: undefined,
        dish_type_id: undefined,
        dish_amount: undefined,
        dish_name: undefined,
        date: undefined,
        is_default_dish: false,
        dish_type_name: undefined
      }
    ])
  }

  const [modalTypePlanFrequencyOpen, setModalTypePlanFrequencyOpen] =
    useState<TModalType>('closed')
  const [idParentCreate, setIdParentCreate] = useState<number>()
  const handleClickOnOpenModalCreatePlanFrequency = (id: number) => {
    setIdParentCreate(id)
    setModalTypePlanFrequencyOpen('create')
  }

  const handleClickOnOpenModalCreateItems = (id: number) => {
    setIdParentCreate(id)
    setModalTypeOpenItems('create')
  }

  const [currentPlanFrequencyUpdate, setCurrentPlanFrequencyUpdate] =
    useState<IPlanFrequencyDefaultValues>()

  const handleClickOnClosePlanFrequency = () => {
    setModalTypePlanFrequencyOpen('closed')
  }

  const handlerOnClickButtonEditInCurrentRowPlanFrequency = (
    currentValue: IPlanFrequency
  ) => {
    const { id, is_active, frequency, payament_form } = currentValue
    const payament_form_obj = JSON.parse(payament_form) as {
      value: string
      periodicity: TPeriodicity
    }

    setCurrentPlanFrequencyUpdate({
      id,
      is_active,
      frequency,
      periodicity: payament_form_obj.periodicity,
      value: payament_form_obj.value
    })
    setModalTypePlanFrequencyOpen('update')
  }

  const handlerOnClickButtonRemoveInCurrentRowPlanFrequency = ({
    id,
    frequency
  }: IPlanFrequency) => {
    setAlertRemovePlanFrequency({
      id,
      name: `plano de frequência ${frequency}`,
      isActive: true
    })
  }

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadMenuFood(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IMenuFoodData>(apiList(id))
        const { data } = response

        const defaultComposition = data.menu_dishes.map(
          (menuDish): IComposition => {
            const hasDate = menuDish.date
            let date
            if (hasDate) {
              date = moment(menuDish.date, 'DD/MM/YYYY').toDate()
            }
            const dishesInfo = JSON.parse(menuDish?.dishes_info)
            return dishesInfo?.map((dishInfo: any) => {
              return {
                id: uuidv4(),
                dish_id: dishInfo.dish_id,
                dish_amount: dishInfo.dish_amount,
                dish_name: dishInfo.dish_name,
                dish_type_id: dishInfo.dish_type_id,
                is_default_dish: dishInfo.is_default_dish,
                date: menuDish.date,
                dish_type_name: dishInfo.dish_type_name
              }
            })
          }
        )

        if (defaultComposition.length > 0) {
          setComposition(defaultComposition)
        }

        setMenuFood(data)

        if (data.items_parent_id) {
          setParamsDishes([{ menu_food_id: `${data.items_parent_id}` }])
        } else {
          setParamsDishes([{ menu_food_id: `${data.id}` }])
        }

        setHasItemsSelect(data.has_items ? 'true' : 'false')
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()

        console.log(err)
        addToast({
          type: 'error',
          title: 'Error ao carregar o item',
          description:
            'Houve um error ao carregar o item, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadMenuFood()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }
  const handlerClickButtonCancellAlertPlanFrequency = () => {
    setAlertRemovePlanFrequency({
      id: 0,
      isActive: false,
      name: ''
    })
  }
  const handlerClickButtonCancellAlertMenuFoodDish = () => {
    setAlertRemoveMenuFoodDish({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Cardápio removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover o cardápio, pois ele está registrado em um plano.'
      })
    }
  }

  const handlerClickButtonConfirmAlertPlanFrequency = async (
    frequencyPlanId: string
  ) => {
    try {
      await api.delete(apiDeletePlanFrequency(frequencyPlanId))
      setAlertRemovePlanFrequency({
        id: 0,
        isActive: false,
        name: ''
      })
      setMenuFood(old => {
        const newMenuFood = { ...old }
        newMenuFood.plans_frequency = old.plans_frequency.filter(
          e => e.id !== +frequencyPlanId
        )
        return newMenuFood
      })
      addToast({
        type: 'success',
        title: 'Plano de frequência removido com sucesso.'
      })
    } catch (err) {
      setAlertRemovePlanFrequency({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover o plano de frequência, pois ele está registrada num contrato.'
      })
    }
  }
  const handlerClickButtonConfirmAlertMenuFoodDish = async (
    menuFoodDishId: string
  ) => {
    try {
      await api.delete(apiDeleteMenuFoodDishes(menuFoodDishId))
      setAlertRemoveMenuFoodDish({
        id: 0,
        isActive: false,
        name: ''
      })
      updateDataTable()
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
    } catch (err) {
      setAlertRemoveMenuFoodDish({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover o item, pois ele está registrada num contrato.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse item, pois ele está registrado num menu.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={menuFood?.menu_food_type?.name}
              label="Refeição"
            />
            <ViewInfoLabel
              col={3}
              description={`${menuFood?.start_validity.slice(
                0,
                10
              )} -  ${menuFood?.end_validity.slice(0, 10)}`}
              label="Vigência"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={12}
              description={menuFood?.description}
              label="Descrição"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={menuFood?.has_items === true ? 'Sim' : 'Não'}
              label="Possui itens"
            />
            <ViewInfoLabel
              col={3}
              description={menuFood?.is_items_parent === true ? 'Sim' : 'Não'}
              label="Cardápio principal"
            />
            <ViewInfoLabel
              col={3}
              description={menuFood?.is_active === true ? 'Sim' : 'Não'}
              label="Ativo"
            />
            <ViewInfoLabel
              col={3}
              description={menuFood?.created_at}
              label="Cadastrado em:"
            />

            <div className="separator my-5" />
            <div className="row">
              <div className="col-md-12">
                <Tabs>
                  <Tab key={0} title="Itens">
                    <div className="portlet light">
                      <div className="card-header p-0">
                        <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                          Listagem
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          {menuFood?.has_items && menuFood?.is_items_parent && (
                            <div>
                              <a
                                href="#"
                                onClick={() =>
                                  handleClickOnOpenModalCreateItems(Number(id))
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <span className="fa fa-plus" /> Adicionar
                              </a>
                            </div>
                          )}
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              setModalPdfItems({
                                open: true
                              })
                            }}
                          >
                            <FaDownload />
                            Baixar relatório
                          </button>
                        </div>
                      </div>
                      <div className="portlet-body form">
                        {paramsDishes.length && (
                          <DataTable
                            format={{ orderBy: 'id' }}
                            orderBySort="DESC"
                            source="menu_food_dishes"
                            entity="MenuFoodDish"
                            onlyView
                            headers={headerMenuDishes}
                            searchParameters={paramsDishes}
                            actions={
                              menuFood?.is_items_parent
                                ? [
                                    {
                                      name: 'view',
                                      title: 'Editar',
                                      spanIcon: 'fa fa-edit',
                                      onClick: items => {
                                        const compositionToUpdate: IComposition[] =
                                          []

                                        items.menu_dishes.forEach(
                                          (menu_dish: IMenuDish) => {
                                            const dishesInfo = JSON.parse(
                                              menu_dish.dishes_info
                                            )
                                            setActualMenuFoodDish(menu_dish.id)
                                            return dishesInfo?.forEach(
                                              (dishInfo: any) => {
                                                const composition: IComposition =
                                                  {
                                                    id: uuidv4(),
                                                    dish_id: dishInfo.dish_id,
                                                    dish_amount:
                                                      dishInfo.dish_amount,
                                                    dish_name:
                                                      dishInfo.dish_name,
                                                    dish_type_id:
                                                      dishInfo?.dish_type_id,
                                                    is_default_dish:
                                                      dishInfo.is_default_dish,
                                                    date: menu_dish.date,
                                                    dish_type_name:
                                                      dishInfo.dish_type_name
                                                  }
                                                compositionToUpdate.push(
                                                  composition
                                                )
                                              }
                                            )
                                          }
                                        )
                                        setNewComposition(compositionToUpdate)
                                        setModalTypeOpenItems('update')
                                      },
                                      style: { margin: '0 5px' }
                                    },
                                    {
                                      name: 'remove',
                                      title: 'Remover',
                                      spanIcon: 'fa fa-remove',
                                      onClick: item => {
                                        setAlertRemoveMenuFoodDish({
                                          id: item.id,
                                          isActive: true,
                                          name: item.date
                                        })
                                      }
                                    }
                                  ]
                                : []
                            }
                          />
                        )}
                      </div>
                    </div>
                  </Tab>
                  <Tab key={1} title="Instituições">
                    <div className="portlet light">
                      <div className="card-header p-0">
                        <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                          Listagem
                        </div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          format={{ orderBy: 'id' }}
                          source="commercial/menuFoodInstitutions"
                          entity="MenuFoodInstitution"
                          onlyView
                          headers={headerMenuInstitutions}
                          searchParameters={searchParams.current}
                          actions={[
                            {
                              name: 'view',
                              title: 'Visualizar',
                              spanIcon: 'fa fa-search',
                              linkTo: institution =>
                                `/commercial/institutions/view/${institution.id}`,
                              style: { margin: '0 5px' }
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab key={2} title="Frequência dos planos">
                    <div>
                      <div className="card-header p-0 d-flex - align-items-center">
                        <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                          Listagem
                        </div>
                        <div className="">
                          <a
                            href="#"
                            onClick={() =>
                              handleClickOnOpenModalCreatePlanFrequency(
                                Number(id)
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <span className="fa fa-plus" /> Adicionar
                          </a>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <MenuTable
                          id="kt_datatable"
                          className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                        >
                          <thead className="border-bottom border-end">
                            <tr>
                              <th>Frequência</th>
                              <th>Periodicidade</th>
                              <th>Valor</th>
                              <th>Ativo</th>
                              <th>Cadastrado em</th>
                              <th className="text-center">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {menuFood?.plans_frequency.length === 0 && (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  Nenhum registro encontrado
                                </td>
                              </tr>
                            )}
                            {menuFood?.plans_frequency?.map(planFrequency => {
                              const payament_form = JSON.parse(
                                planFrequency.payament_form
                              )
                              const periodicity = payament_form.periodicity
                              const value = payament_form.value
                              return (
                                <MenuTableRow key={planFrequency.id}>
                                  <td>{planFrequency.frequency}</td>
                                  <td>{periodicity}</td>
                                  <td>{currencyMask(value)}</td>
                                  <td>
                                    {planFrequency.is_active === true
                                      ? 'Sim'
                                      : 'Não'}
                                  </td>
                                  <td>{planFrequency.created_at}</td>
                                  <td>
                                    <LinkContainer className="gap-2">
                                      <button
                                        onClick={() =>
                                          handlerOnClickButtonEditInCurrentRowPlanFrequency(
                                            planFrequency
                                          )
                                        }
                                        style={{
                                          border: 'none',
                                          color: '#009EF7',
                                          background: 'none'
                                        }}
                                      >
                                        <span className="fa fa-edit" />
                                      </button>
                                      <button
                                        onClick={() =>
                                          handlerOnClickButtonRemoveInCurrentRowPlanFrequency(
                                            planFrequency
                                          )
                                        }
                                        className="text-decoration-none"
                                        style={{
                                          border: 'none',
                                          color: '#009EF7',
                                          background: 'none'
                                        }}
                                      >
                                        <span className="fa fa-remove" />
                                      </button>
                                    </LinkContainer>
                                  </td>
                                </MenuTableRow>
                              )
                            })}
                          </tbody>
                        </MenuTable>
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    title="Histórico"
                    key={menuFood?.menu_foods_related ? 4 : 3}
                  >
                    <div className="portlet light">
                      <div
                        className="portlet-title mt-3 d-flex justify-content-between"
                        style={{ height: '34.4px' }}
                      >
                        <div className="caption fs-5 fw-bold">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          entityId={id}
                          format={{ orderBy: '' }}
                          source="auditLogs"
                          entity="AuditLog"
                          searchParameters={searchParametersAuditLog.current}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        onClickButtonCancel={handleClickOnCloseModalPdfItems}
        isOpenModal={modalPdfItems.open}
        pageTitle={'Itens do cardápio'}
        Children={
          <FormPdfItems
            menu_food_id={+id}
            isOpenInModal={{ handleOnClose: handleClickOnCloseModalPdfItems }}
            menuFood={menuFood}
          />
        }
      />

      <Modal
        refModal={refModalFrequency}
        onClickButtonCancel={handleClickOnClosePlanFrequency}
        isOpenModal={modalTypePlanFrequencyOpen !== 'closed'}
        pageTitle={
          modalTypePlanFrequencyOpen === 'create' ? 'Adicionar' : 'Editar'
        }
        Children={
          <FormPlanFrequency
            typeForm={
              modalTypePlanFrequencyOpen === 'create' ? 'create' : 'update'
            }
            menu_food={{ id: menuFood?.id, name: menuFood?.name }}
            isOpenInModal={{
              handleOnClose: handleClickOnClosePlanFrequency,
              idParent: Number(idParentCreate)
            }}
            setMenuFood={setMenuFood}
            initialValues={
              modalTypePlanFrequencyOpen === 'update' && {
                ...currentPlanFrequencyUpdate,
                idUpdate: currentPlanFrequencyUpdate?.id
              }
            }
          />
        }
      />

      <Modal
        refModal={refModalItems}
        onClickButtonCancel={handleClickOnCloseItems}
        isOpenModal={modalTypeOpenItems !== 'closed'}
        pageTitle={modalTypeOpenItems === 'create' ? 'Adicionar' : 'Editar'}
        style={{
          stylesModal: {
            height: '100%',
            width: '80%'
          }
        }}
        withoutPadding={true}
        Children={
          <FormAddItem
            typeForm={modalTypeOpenItems === 'create' ? 'create' : 'update'}
            isOpenInModal={{
              handleOnClose: handleClickOnCloseItems,
              idParent: Number(id)
            }}
            actualMenuFoodDish={actualMenuFoodDish}
            menuFoodId={menuFood?.id}
          />
        }
      />

      <Alert
        message={`Tem certeza que deseja excluir o registro ${alertRemovePlanFrequency.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlertPlanFrequency}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlertPlanFrequency(
            String(alertRemovePlanFrequency.id)
          )
        }}
        isActive={alertRemovePlanFrequency.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro da data ${alertRemoveMenuFoodDish.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlertMenuFoodDish}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlertMenuFoodDish(
            String(alertRemoveMenuFoodDish.id)
          )
        }}
        isActive={alertRemoveMenuFoodDish.isActive}
      />

      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${menuFood?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(menuFood?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default MenuFoodView
